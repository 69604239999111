import './asset/css/App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {
	PageDoesNotExist,
	PublicRoute,
	GestionLocataires, Unsubscribe,
} from './components'
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from 'react-query/devtools'

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnmount: false,
			refetchOnReconnect: false,
			retry: false,
		},
	},
});

export default function AppContainer() {
	return (
		<QueryClientProvider client={queryClient}>
				<App/>
				<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	)
};

function App() {
	return (
		<div className="App" id="top-anchor">
			<BrowserRouter>
				<Routes>
					<Route exact path='/' element={<PublicRoute element={<GestionLocataires/>} />} />
					<Route exact path='/gestionlocataires' element={<PublicRoute element={<GestionLocataires/>} />} />
					<Route exact path='/unsubscribe/:id' element={<PublicRoute element={<Unsubscribe/>} />} />
					<Route path='*' element={<PublicRoute element={<PageDoesNotExist/>} />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}
