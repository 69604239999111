import axios from 'axios'

class ApiClient {
    constructor(remoteHostUrl) {
        this.remoteHostUrl = remoteHostUrl
        this.tokenName = "profiljardins"
        this.token = null
    }

    setToken(token){
        this.token = token
        localStorage.setItem(this.tokenName, token)
    }

    async request({ endpoint, method = 'GET', data = {}}) {
        const url = `${this.remoteHostUrl}/${endpoint}`
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: this.token ? `Bearer ${this.token}` : "",
        }

        try {
            const res = await axios({ url, method, data, headers })
            return { data: res.data, error: null }
        } catch (error) {
            console.error("APIclient.makeRequest.error:")
            console.error({ errorResponse: error.response })
            throw(error?.response?.data?.error?.message || String(error))
        }
    }
    
    // ----------------------------------------
    // LOGS
    // ----------------------------------------
    async addLog(log) {
        return await this.request({ endpoint: `logs/addlog`, method: `POST`, data: log })
    }

    // ----------------------------------------
    // CLIENTS - Gestion Locataire
    // ----------------------------------------

    async fetchOwner(contract) {
        return await this.request({ endpoint: `clients/gestion/${contract.clientContract}/${contract.clientReference}`, method: `GET` })
    }

    async crudTenantsList(tenantsList) {
        return await this.request({ endpoint: `clients/gestion/tenants/`, method: `POST`, data:tenantsList })
    }
    async crudTenantsEmailList(tenantsList) {
        return await this.request({ endpoint: `clients/gestion/tenantsemail/`, method: `POST`, data:tenantsList })
    }
    async crudClientsInfos(owner) {
        return await this.request({ endpoint: `clients/gestion/`, method: `POST`, data: owner })
    }

    async deleteTenantGestion(id) {
        return await this.request({ endpoint: `tenants/gestion/${id}`, method: `DELETE` })
    }
    async deleteTenantEmailGestion(id) {
        return await this.request({ endpoint: `tenantsemail/${id}`, method: `DELETE` })
    }

    async addLogGestionLocataire(log) {
        return await this.request({ endpoint: `logs/addloglocataire`, method: `POST`, data: log })
    }
}


export default new ApiClient(process.env.REACT_APP_REMOTE_HOST_URL)
